@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: light) {
  .shiki.dark-plus {
    display: none;
  }
}
@media (prefers-color-scheme: dark) {
  .shiki.light-plus {
    display: none;
  }
}

@font-face {
  font-family: "RandMono";
  src: url("/fonts/Rand-Mono-Regular/Rand-Mono-Regular.woff2") format("woff2"),
    url("/fonts/Rand-Mono-Regular/Rand-Mono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* StabilGrotesk - Hairline */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Hairline.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Hairline.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/* StabilGrotesk - Hairline Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-HairlineItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-HairlineItalic.woff")
      format("woff");
  font-weight: 100;
  font-style: italic;
}

/* StabilGrotesk - Thin */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Thin.woff2") format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

/* StabilGrotesk - Thin Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-ThinItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-ThinItalic.woff")
      format("woff");
  font-weight: 200;
  font-style: italic;
}

/* StabilGrotesk - Light */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Light.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

/* StabilGrotesk - Light Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-LightItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-LightItalic.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
}

/* StabilGrotesk - Regular */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Regular.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

/* StabilGrotesk - Regular Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-RegularItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-RegularItalic.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
}

/* StabilGrotesk - Medium */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Medium.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

/* StabilGrotesk - Medium Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-MediumItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-MediumItalic.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
}

/* StabilGrotesk - Bold */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Bold.woff2") format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/* StabilGrotesk - Bold Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-BoldItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-BoldItalic.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
}

/* StabilGrotesk - Black */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Black.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

/* StabilGrotesk - Black Italic */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-BlackItalic.woff2")
      format("woff2"),
    url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-BlackItalic.woff")
      format("woff");
  font-weight: 900;
  font-style: italic;
}

/* StabilGrotesk - Variable */
@font-face {
  font-family: "StabilGrotesk";
  src: url("/fonts/Stabil-Grotesk/Web/StabilGrotesk-Variable.woff2")
    format("woff2");
  font-weight: var(--font-weight);
  font-style: normal;
}

/* Theme colors */
:root {
  --color-primary: #334155;
  --color-primary-dark: #e2e8f0;
  --color-secondary: #191919;
  --color-tertiary: #484848;
  --color-accent: #cba991;
  --color-secondary-accent: #cba9913d;
  --border-color-primary: #e5e7eb;
  --border-color-primary-dark: #262626;
  --border-color-hover: rgba(209, 213, 219);
  --background-color-hover: rgba(248, 250, 252);
}

.light {
  --mermaid-text-color: rgba(107, 114, 128, 1); /* Default/light mode color */
  --mermaid-border-color: var(--border-color-primary);
  --mermaid-background-color: white;
  --step-border-color: white;
  --step-text-color: rgb(163, 163, 163);
  --step-background-color: rgb(243 244 246);
  --primary-accent-color: #b29580;
  --primary-background-color: #cba99156;
}

.dark {
  --mermaid-text-color: rgba(163, 163, 163, 1); /* Dark mode color */
  --mermaid-border-color: var(--border-color-primary-dark);
  --mermaid-background-color: var(--color-secondary);
  --mermaid-line-color: var(--border-color-primary-dark);
  --step-border-color: rgb(17, 17, 17);
  --step-text-color: rgb(163, 163, 163);
  --step-background-color: rgb(38, 38, 38);
  --primary-accent-color: #cba991;
  --primary-background-color: #cba9913d;
}

[type="button"]:not(.bg-none) {
  background-color: inherit;
}

@layer components {
  .steps-container {
    @apply relative;
    counter-reset: step;
    margin-left: 1rem;
  }

  .step {
    @apply mb-8 pl-4 relative;
  }

  .step-title {
    @apply text-xl font-semibold mb-4;
    counter-increment: step;
  }

  .step-title::before {
    content: counter(step);
    content: counter(step);
    position: absolute;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    text-indent: -1px;
    justify-content: center;
    background-color: var(--step-background-color) !important;
    color: var(--step-text-color) !important;
    border-radius: 50%;
    border: 4px solid var(--step-background-color) !important;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    left: -2rem;
    top: 0rem;
  }
}

/* Light mode theme */
._text-primary-800 {
  color: var(--primary-accent-color) !important;
}
._bg-primary-100 {
  background-color: var(--primary-background-color) !important;
}
.hover\:_text-primary-600:hover {
  color: var(--primary-accent-color) !important;
}
.hover\:_text-gray-900:hover {
  color: var(--primary-accent-color) !important;
}
.hover\:_bg-gray-100:hover {
  background-color: var(--primary-background-color) !important;
}
.hover\:_text-gray-800:hover {
  --tw-text-opacity: 1;
  color: var(--primary-accent-color) !important;
  transition: all 200ms;
}
input:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: var(--primary-background-color) !important;
  --tw-ring-offset-color: var(--primary-background-color) !important;
}
/* END Light mode theme */

/* Dark mode theme */
/* Light mode theme */
._text-primary-600 {
  color: var(--primary-accent-color) !important;
}

/* Dark mode theme */
/* :is(html[class~="dark"] ._text-primary-600) {
  color: var(--primary-accent-color) !important;
} */
:is(html[class~="dark"] .dark\:_text-primary-600) {
  --tw-text-opacity: 1;
  color: var(--primary-accent-color) !important;
}
:is(html[class~="dark"] .dark\:_bg-primary-400\/10) {
  background-color: var(--primary-background-color) !important;
}
:is(html[class~="dark"] .dark\:hover\:_text-gray-50:hover) {
  color: var(--primary-accent-color) !important;
}
:is(html[class~="dark"] .dark\:hover\:_bg-primary-100\/5:hover) {
  background-color: var(--primary-background-color) !important;
}
:is(html[class~="dark"] .dark\:hover\:_bg-primary-50\/5:hover) {
  background-color: var(--primary-background-color) !important;
}
._bg-primary-50 {
  background-color: var(--primary-background-color) !important;
}
._text-primary-600 {
  color: var(--primary-accent-color) !important;
}
:is(html[class~="dark"] .dark\:hover\:_text-gray-200:hover) {
  color: var(--primary-accent-color) !important;
}
/* search bar */
:is(html[class~="dark"] input:focus-visible) {
  --tw-ring-opacity: 0.5;
  --tw-ring-color: var(--primary-background-color) !important;
  --tw-ring-offset-color: var(--primary-accent-color) !important;
}

.mermaid {
  background-color: transparent;
  border: 1px solid var(--mermaid-text-color) !important;
  padding: 8px;
  border-radius: 4px;
}

.actor {
  fill: var(
    --mermaid-background-color
  ) !important; /* Background color for actors */
  stroke: var(--mermaid-text-color) !important; /* Border color for actors */
}

.mermaid g line {
  stroke: var(--mermaid-text-color) !important;
}

.actor tspan {
  fill: var(--mermaid-text-color) !important; /* Background color for actors */
}

.arrowhead {
  fill: var(--mermaid-text-color) !important;
}

.messageText {
  fill: var(--mermaid-text-color) !important; /* Text color for messages */
}

.messageLine0,
.messageLine1 {
  stroke: var(--mermaid-text-color) !important; /* Line color for messages */
  stroke-width: 1.5; /* Line thickness for messages */
}

.marker {
  fill: var(--mermaid-line-color) !important; /* Arrow and marker color */
}

.activation0,
.activation1,
.activation2 {
  fill: var(
    --mermaid-background-color
  ) !important; /* Background color for activation bars */
  stroke: var(
    --mermaid-text-color
  ) !important; /* Border color for activation bars */
}

/* Style for cluster rectangles */
.clusters .cluster rect {
  fill: var(--mermaid-background-color) !important;
  stroke: var(--mermaid-text-color) !important;
}

/* Style for cluster labels */
.cluster-label .nodeLabel {
  fill: #ff0000 !important;
  color: var(--mermaid-text-color) !important;
}

/* Style for edge paths */
.edgePaths path {
  stroke: #666666;
  stroke-width: 2px;
}

/* Style for edge labels (if applicable) */
.edgeLabels .edgeLabel .label {
  color: var(--mermaid-text-color) !important;
}

/* Style for node rectangles */
.nodes .node rect {
  fill: var(--mermaid-background-color) !important;
  stroke: var(--mermaid-text-color) !important;
}

/* Style for node labels */
.nodes .nodeLabel {
  color: var(--mermaid-text-color) !important;
}

.textareaStyle {
  color: #ce9178;
  padding: 2px 0px;
  border-radius: 4px;
}

.retrieve-proof-container {
  font-family: monospace;
  padding: 8px;
  background-color: #f3f4f6;
  overflow: auto;
  position: relative;
  color: black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* max-width: 100%; */
  max-width: 500px;
}

/* Mobile styles */
@media (max-width: 600px) {
  .retrieve-proof-container {
    font-size: 12px;
    padding: 6px;
    /* max-width: 90vw; */
    max-height: 50vh;
  }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 1023px) {
  .retrieve-proof-container {
    font-size: 12px;
    /* max-width: 50vw; */
    max-height: 50vh;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .retrieve-proof-container {
    font-size: 12px;
    /* max-width: 25vw; */
    max-height: 50vh;
  }
}

.AccordionContent {
  overflow: hidden;
}
.AccordionContent[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.AccordionContent[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.sceditor > pre:focus-visible {
  outline: none !important;
  outline-color: transparent !important;
  outline-width: 0px !important;
}

:is(html[class~="dark"]) .token.operator {
  background-color: transparent !important;
}

.nextra-banner-container {
  background: linear-gradient(
    1deg,
    #1e40af 0%,
    #2563eb 50%,
    #3b82f6 100%
  ) !important;
}

.dark .nextra-banner-container {
  background: linear-gradient(
    1deg,
    #1e3a8a 0%,
    #1e40af 50%,
    #2563eb 100%
  ) !important;
}
