/* Since Pre overlays Textarea, these two must be identical */
.light {
  --hljs-text-color: rgb(163, 163, 163);
  --hljs-string: #a31515; /* Darker tone for light mode */
  --hljs-meta: #333; /* Darker grey for visibility */
  --hljs-meta-keyword: #a64ca6; /* Adjusted for contrast */
  --hljs-class-title: #2a8674; /* Darker green */
  --hljs-function-title: #6a9955; /* Darker green for contrast */
  --hljs-title: #2a8674; /* Consistent with class title */
  --hljs-function-keyword: #3972a2; /* Darker blue */
  --hljs-number: #098658; /* Adjusted for visibility */
  --hljs-type-modifier: #3972a2; /* Darker blue for clarity */
  --hljs-type-primitive: #2a8674; /* Consistent with class title */
  --hljs-function: #3972a2; /* Darker blue for function */
  --hljs-params: #1c6b92; /* Adjusted for better contrast */
  --hljs-class-keyword: #3972a2; /* Consistent with function keyword */
  --hljs-built_in: #3972a2; /* Consistent with other blue tones */
  --hljs-keyword: #a64ca6; /* Adjusted for contrast */
  --hljs-type-control: #a64ca6; /* Consistent with keyword */
  --hljs-operator-color: black;
  --hljs-attr: #0451a5;
}

.dark {
  --hljs-text-color: #484848;
  --hljs-string: #ce9178;
  --hljs-meta: #fff;
  --hljs-meta-keyword: #c586c0;
  --hljs-class-title: #4ec9b0;
  --hljs-function-title: #dcdcaa;
  --hljs-title: #dcdcaa;
  --hljs-function-keyword: #569cd6;
  --hljs-number: #b5cea8;
  --hljs-type-modifier: #569cd6;
  --hljs-type-primitive: #4ec9b0;
  --hljs-function: #569cd6;
  --hljs-params: #9cdcfe;
  --hljs-class-keyword: #569cd6;
  --hljs-built_in: #569cd6;
  --hljs-keyword: #c586c0;
  --hljs-type-control: #c586c0;
  --hljs-operator-color: white;
  --hljs-attr: #9cdcfe;
}

.code-editor pre,
.code-editor textarea {
  @apply pt-1 pl-4 !important;
}

.code-editor.with-numbers pre,
.code-editor.with-numbers textarea {
  @apply pl-10 !important;
}

.code-editor textarea {
  @apply outline-none;
}

.light .code-editor textarea {
  caret-color: #000;
}

.dark .code-editor textarea {
  caret-color: #fff;
}

.code-editor .line-number {
  @apply absolute left-0 text-right w-8;
  color: var(--hljs-text-color) !important;
}

.hljs-string {
  color: var(--hljs-string) !important;
}

.hljs-meta {
  color: var(--hljs-meta) !important;
}

.hljs-meta .hljs-keyword {
  color: var(--hljs-meta-keyword) !important;
}

/* contract name */
.hljs-class .hljs-title {
  color: var(--hljs-class-title) !important;
}

/* // this is function title  */
.hljs-function .hljs-title {
  color: var(--hljs-function-title) !important;
  /* color: #4ec9b0 !important; */
}

.hljs-title {
  color: var(--hljs-title) !important;
}

/* // this is function title  */
.hljs-function .hljs-keyword {
  color: var(--hljs-function-keyword) !important;
}

/* // return   */
.hljs-function .hljs-title .hljs-keyword {
  color: var(--hljs-function-keyword) !important;
}

.hljs-number {
  color: var(--hljs-number) !important;
}

.hljs-type-modifier {
  color: var(--hljs-type-modifier) !important;
}

.hljs-type-primitive {
  color: var(--hljs-type-primitive) !important;
}

.hljs-function {
  color: var(--hljs-function) !important;
}

.hljs-params {
  color: var(--hljs-params);
}

.hljs-class .hljs-keyword {
  color: var(--hljs-class-keyword) !important;
}

.hljs-built_in {
  color: var(--hljs-built_in) !important;
}

.hljs-keyword {
  /* color: #c586c0 !important; */
  color: var(--hljs-keyword) !important;
}

.hljs-type-control {
  /* color: #c586c0 !important; */
  color: var(--hljs-type-control) !important;
}

.hljs-operator {
  color: var(--hljs-operator-color) !important;
}

.hljs-keyword .hljs-type-complex {
  color: var(--hljs-function-keyword) !important;
}

.hljs-comment {
  color: var(--hljs-text-color) !important;
}

.hljs-comment > * {
  color: var(--hljs-text-color) !important;
}

.hljs-function-name .hljs-name {
  color: var(--hljs-function-title) !important;
}
.hljs-name {
  color: var(--hljs-function-title) !important;
}

.hljs-attr {
  color: var(--hljs-attr) !important;
}

.keyStyle {
  font-family: "Courier New", Courier, monospace;
  color: #4a90e2;

  padding: 2px 0px;
  border-radius: 4px;
}

.valueStyle {
  /* font-family: 'Courier New', Courier, monospace; */
  color: #ce9178; /* Orange color for values */

  padding: 2px 0px;
  border-radius: 4px;
}
